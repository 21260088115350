var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reg"},[_c('navBar',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"step"},[_c('van-steps',{attrs:{"active":_vm.active,"active-color":"#38f","active-icon":"success"}},[_c('van-step',[_vm._v("用户信息")]),_c('van-step',[_vm._v("企业信息")]),_c('van-step',[_vm._v("收货信息")])],1)],1),_c('div',{staticClass:"regInformation"},[(_vm.active == 0)?_c('el-form',{ref:"form0",attrs:{"model":_vm.info}},[_c('el-form-item',{ref:"userName",attrs:{"rules":[
          {  message: '请输入用户名', trigger: 'blur' }
        ],"prop":"userName"}},[_c('el-input',{attrs:{"placeholder":"请输入用户名"},model:{value:(_vm.info.userName),callback:function ($$v) {_vm.$set(_vm.info, "userName", $$v)},expression:"info.userName"}})],1),_c('el-form-item',{ref:"email",attrs:{"rules":[
          { message: '请输入邮箱', trigger: 'blur' } ],"prop":"email"}},[_c('el-input',{attrs:{"placeholder":"请输入邮箱"},model:{value:(_vm.info.email),callback:function ($$v) {_vm.$set(_vm.info, "email", $$v)},expression:"info.email"}})],1),_c('el-form-item',{ref:"loginphone",attrs:{"rules":[
          { required: true, message: '请输入手机号', trigger: 'blur' } ],"prop":"loginPhone"}},[_c('el-input',{attrs:{"oninput":"if(value.length>11)value=value.slice(0,11)","placeholder":"请输入手机号","type":"number"},on:{"blur":_vm.ifNumber},model:{value:(_vm.info.loginPhone),callback:function ($$v) {_vm.$set(_vm.info, "loginPhone", $$v)},expression:"info.loginPhone"}})],1),_c('el-form-item',{staticClass:"code",attrs:{"rules":[{ message: '请输入验证码', trigger: 'blur' }]}},[_c('el-input',{staticClass:"vcode",attrs:{"placeholder":"请输入验证码","type":"number"},model:{value:(_vm.vcode),callback:function ($$v) {_vm.vcode=$$v},expression:"vcode"}}),_c('el-button',{attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.sendCode}},[_vm._v(" "+_vm._s(_vm.buttonName)+" ")])],1),_c('el-form-item',{attrs:{"rules":[{ required: true, message: '请输入密码', trigger: 'blur' }],"prop":"password"}},[_c('el-input',{attrs:{"placeholder":"请输入密码"},model:{value:(_vm.info.password),callback:function ($$v) {_vm.$set(_vm.info, "password", $$v)},expression:"info.password"}})],1)],1):_vm._e(),(_vm.active == 1)?_c('el-form',{ref:"form1",attrs:{"model":_vm.info.enterpriseInfos[0]}},[_c('el-form-item',{attrs:{"rules":[
          { message: '请输入企业名称', trigger: 'blur' }
        ],"prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入企业名称"},model:{value:(_vm.info.enterpriseInfos[0].name),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "name", $$v)},expression:"info.enterpriseInfos[0].name"}})],1),_c('el-form-item',{attrs:{"rules":[
          {  message: '请输入信用代码', trigger: 'blur' }
        ],"prop":"creditCode"}},[_c('el-input',{ref:_vm.refList.cardInput,attrs:{"placeholder":"请输入信用代码"},on:{"input":function($event){return _vm.inputs(_vm.info.enterpriseInfos[0].creditCode)}},model:{value:(_vm.info.enterpriseInfos[0].creditCode),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "creditCode", $$v)},expression:"info.enterpriseInfos[0].creditCode"}})],1),_c('el-form-item',{attrs:{"rules":[
          {  message: '请输入注册地址', trigger: 'blur' }
        ],"prop":"registerAddress"}},[_c('el-input',{attrs:{"placeholder":"请输入注册地址"},model:{value:(_vm.info.enterpriseInfos[0].registerAddress),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "registerAddress", $$v)},expression:"info.enterpriseInfos[0].registerAddress"}})],1),_c('el-form-item',{attrs:{"rules":[
          {  message: '请输入注册电话', trigger: 'blur' },
          { pattern: /\d/, message: '电话号输入有误' }
        ],"prop":"phone"}},[_c('el-input',{attrs:{"placeholder":"请输入注册电话","type":"number"},model:{value:(_vm.info.enterpriseInfos[0].phone),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "phone", $$v)},expression:"info.enterpriseInfos[0].phone"}})],1),_c('el-form-item',{attrs:{"prop":"accountOpening"}},[_c('el-input',{attrs:{"placeholder":"请输入开户行"},model:{value:(_vm.info.enterpriseInfos[0].accountOpening),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "accountOpening", $$v)},expression:"info.enterpriseInfos[0].accountOpening"}})],1),_c('el-form-item',{attrs:{"prop":"bankAccount"}},[_c('el-input',{ref:_vm.refList.cardInputs,attrs:{"placeholder":"请输入银行账号"},on:{"input":function($event){return _vm.formatCardNumber(
              _vm.refList.cardInputs,
              _vm.info.enterpriseInfos[0].bankAccount,
              _vm.info
            )}},model:{value:(_vm.info.enterpriseInfos[0].bankAccount),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "bankAccount", $$v)},expression:"info.enterpriseInfos[0].bankAccount"}})],1)],1):_vm._e(),(_vm.active == 2)?_c('el-form',{ref:"form2",attrs:{"model":_vm.info.enterpriseAddresses[0]}},[_c('el-form-item',{attrs:{"rules":[
          {  message: '请输入店名', trigger: 'blur' }
        ],"prop":"companyName"}},[_c('el-input',{attrs:{"placeholder":"请输入店名"},model:{value:(_vm.info.enterpriseAddresses[0].companyName),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "companyName", $$v)},expression:"info.enterpriseAddresses[0].companyName"}})],1),_c('el-form-item',{attrs:{"rules":[
          { required: true, message: '请输入收货人名称', trigger: 'blur' }
        ],"prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入收货人名称"},model:{value:(_vm.info.enterpriseAddresses[0].name),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "name", $$v)},expression:"info.enterpriseAddresses[0].name"}})],1),_c('el-form-item',{attrs:{"rules":[
          { required: true, message: '请输入注册电话', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '电话号输入有误' }
        ],"prop":"phone"}},[_c('el-input',{attrs:{"placeholder":"请输入注册电话","type":"number"},model:{value:(_vm.info.enterpriseAddresses[0].phone),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "phone", $$v)},expression:"info.enterpriseAddresses[0].phone"}})],1),_c('el-form-item',{attrs:{"rules":[
          { required: true, message: '请输入收货地址', trigger: 'blur' }
        ],"prop":"address"}},[_c('el-input',{attrs:{"placeholder":"请输入收货地址"},model:{value:(_vm.info.enterpriseAddresses[0].address),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "address", $$v)},expression:"info.enterpriseAddresses[0].address"}})],1)],1):_vm._e(),_c('div',{staticClass:"submits"},[(_vm.active < 2)?_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":_vm.next}},[_vm._v("下一步")]):_vm._e(),(_vm.active == 2)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.registerBtn}},[_vm._v("立即注册")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }